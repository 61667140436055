import { Route, Routes } from 'react-router-dom';
import MainPage from '../src/pages/main.page';
import PropertyDetails from '../src/pages/property-details.page';
import {properties} from './data/properties.data.js';
import './App.css';
import ScrollToTop from './helpers/scrollToTop';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path='/' element={<MainPage properties={properties} />} />
        <Route path='/property/:link' element={<PropertyDetails properties={properties} />} />
      </Routes>
    </>
  );
}

export default App;
