import { Link } from 'react-router-dom';
import './properties.style.scss';

export default function Properties({ properties }) {
  return (
    <div className="bg-white" id="properties">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
          {properties.map((property) => (
            <Link key={property.id} to={`/property/${property.link}`} className="group property-card px-2 py-2 rounded-lg">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={property.imageSrc}
                  alt={property.imageAlt}
                  className="h-40 w-full object-cover object-center"
                />
              </div>
              <div className="pb-4">
                <h3 className="line-clamp-1 mt-2 text-lg font-medium text-gray-900 ">{property.name}</h3>
                <p className='mt-1 text-sm text-gray-600 flex items-center location'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                  <span className='line-clamp-1'>&nbsp;{property.location.title}</span>
                </p>
                <p className="mt-4 text-lg font-medium ttext-gray-700 text-secondary price">{property.price} RF</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
