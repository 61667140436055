import EagleRidgeCover from '../assets/eagle-ridge/1.jpg';
import MetroSouthCover from '../assets/metro-south/1.jpg';
import SouthCoastCover from '../assets/south-coast/1.jpg';
import CalirayaSpringCover from '../assets/caliraya-spring/1.jpg';
import BeverlyPlaceCover from '../assets/beverly-place/1.jpg';
import SaddleClub from '../assets/saddle-club/1.jpg';
import AquamiraHotel from '../assets/aquamira-hotel/1.jpg';

import er_a from '../assets/eagle-ridge/1.jpg';
import er_b from '../assets/eagle-ridge/2.jpg';
import er_c from '../assets/eagle-ridge/3.jpg';
import er_d from '../assets/eagle-ridge/4.jpg';

// metro south
import ms_a from '../assets/metro-south/1.jpg';
import ms_b from '../assets/metro-south/2.jpg';
import ms_c from '../assets/metro-south/3.jpg';
import ms_d from '../assets/metro-south/4.jpg';
import ms_e from '../assets/metro-south/5.jpg';
import ms_f from '../assets/metro-south/6.jpg';
import ms_g from '../assets/metro-south/7.jpg';

// caliraya
import cs_a from '../assets/caliraya-spring/1.jpg';
import cs_b from '../assets/caliraya-spring/2.jpg';
import cs_c from '../assets/caliraya-spring/3.jpg';
import cs_d from '../assets/caliraya-spring/4.jpg';
import cs_e from '../assets/caliraya-spring/5.jpg';
import cs_f from '../assets/caliraya-spring/6.jpg';
import cs_g from '../assets/caliraya-spring/7.jpg';
import cs_h from '../assets/caliraya-spring/8.jpg';
import cs_i from '../assets/caliraya-spring/9.jpg';

// beverly place
import bp_a from '../assets/beverly-place/1.jpg';
import bp_b from '../assets/beverly-place/2.jpg';
import bp_c from '../assets/beverly-place/3.jpg';
import bp_d from '../assets/beverly-place/4.jpg';
import bp_e from '../assets/beverly-place/5.jpg';
import bp_f from '../assets/beverly-place/6.jpg';
import bp_g from '../assets/beverly-place/7.jpg';
import bp_h from '../assets/beverly-place/8.jpg';
import bp_i from '../assets/beverly-place/9.jpg';
import bp_j from '../assets/beverly-place/10.jpg';
import bp_k from '../assets/beverly-place/11.jpg';
import bp_l from '../assets/beverly-place/12.jpg';
import bp_m from '../assets/beverly-place/13.jpg';
import bp_n from '../assets/beverly-place/14.jpg';
import bp_o from '../assets/beverly-place/15.jpg';

// saddle club
import sc_a from '../assets/saddle-club/1.jpg';
import sc_b from '../assets/saddle-club/2.jpg';
import sc_c from '../assets/saddle-club/3.jpg';
import sc_d from '../assets/saddle-club/4.jpg';
import sc_e from '../assets/saddle-club/5.jpg';
import sc_f from '../assets/saddle-club/6.jpg';
import sc_g from '../assets/saddle-club/7.jpg';
import sc_h from '../assets/saddle-club/8.jpg';
import sc_i from '../assets/saddle-club/9.jpg';
import sc_j from '../assets/saddle-club/10.jpg';
import sc_k from '../assets/saddle-club/11.jpg';
import sc_l from '../assets/saddle-club/12.jpg';
import sc_m from '../assets/saddle-club/13.jpg';
import sc_n from '../assets/saddle-club/14.jpg';
import sc_o from '../assets/saddle-club/15.jpg';

// aquamira hotel
import ah_a from '../assets/aquamira-hotel/1.jpg';
import ah_b from '../assets/aquamira-hotel/2.jpg';
import ah_c from '../assets/aquamira-hotel/3.jpg';
import ah_d from '../assets/aquamira-hotel/4.jpg';
import ah_e from '../assets/aquamira-hotel/5.jpg';
import ah_f from '../assets/aquamira-hotel/6.jpg';
import ah_g from '../assets/aquamira-hotel/7.jpg';
import ah_h from '../assets/aquamira-hotel/8.jpg';
import ah_i from '../assets/aquamira-hotel/9.jpg';
import ah_j from '../assets/aquamira-hotel/10.jpg';

export const properties = [
  {
    id: 1,
    tags: [],
    link: 'eagle-ridge',
    name: 'Eagle Ridge Golf & Country Club',
    swiper_images: {
      src: [er_a, er_b, er_c, er_d],
      alt: "eagle ridge images"
    },
    location: {
      title: 'General Trias, Cavite',
      detail: "North Blvd, General Trias, Cavite Philippines",
    },
    special_offer: "Free Lifetime Golf membership",
    price: '₱ 50,000',
    imageSrc: EagleRidgeCover,
    imageAlt: 'Eagle Ridge Country Club Nolly Saludes NBS Marketing for sale properties',
    description: "Welcome to the prestigious world of Eagle Ridge Golf and Country Club Land realty, situated within the renowned Eagle Ridge Golf and Residential Estate in Gen. Trias, Cavite. Immerse yourself in a life of luxury surrounded by verdant landscapes and unparalleled amenities. From rejuvenating spa treatments, Jacuzzi, and massages to exquisite dining options and a Member's Lounge, this exclusive community offers an array of facilities for relaxation and entertainment. Stay active with top-notch sports facilities including a gym, tennis courts, basketball court, squash court, and badminton courts. Weekends are a delight with barbecue gardens, children's play areas, and swimming pools for both adults and kids. Experience the pinnacle of refined living at Eagle Ridge, where every moment is filled with pleasure and opulence.",
    amenities: [
      "Cinema",
      "Golfer's Lounge",
      "Jacuzzi and Spa",
      "Covered basketball court",
      "Children's playground",
      "Grand lobby and reception",
      "Fine dining restaurant",
      "Computer game room",
      "Swimming pools with water slides",
      "Fishing",
      "Fitness Gym",
      "Car park",
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 2,
    tags: [],
    link: 'metro-south',
    name: 'Metro South Executive Village',
    swiper_images: {
      src: [ms_a, ms_b, ms_c, ms_d, ms_e, ms_f, ms_g],
      alt: "metro south excutive village image"
    },
    location: {
      title: 'General Trias, Cavite',
      detail: 'Manggahan, General Trias, Cavite, Philippines',
    },
    price: '₱ 10,000',
    imageSrc: MetroSouthCover,
    imageAlt: 'Metro South Village gentri Nolly Saludes property for sale',
    description: "Discover a world of opportunity in Metro South's land realty market. With a prime location in a thriving metropolis, Metro South offers a diverse range of properties for sale, from residential to commercial options. Whether you're looking to build your dream home or invest in a bustling business district, Metro South's land realty presents a chance to secure a valuable asset in a dynamic and rapidly developing urban landscape.",
    amenities: [
      "Centralized water system.",
      "Underground drainage system",
      "Complete electrical system supplied by Meralco",
      "Covered shell tennis court & covered basketball court",
      "Olympic type swimming pool",
      "Main clubhouse",
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 3,
    tags: [],
    link: 'south-coast',
    name: 'South Coast Lian Batangas',
    location: {
      title: 'Lian Batangas',
      detail: "Brgy. Matabungkay & Brgy. Balibago Lian, Batangas, Philippines"
    },
    price: '₱ 10,000',
    imageSrc: SouthCoastCover,
    imageAlt: 'Lian Batangas for sale property Nolly Saludes South Coast Batangas NBS marketing property',
    description: "Discover the perfect opportunity to own a piece of paradise on the South Coast of Lian, Batangas. This land realty for sale presents a rare chance to embrace the breathtaking beauty of this coastal haven. With its pristine beaches, turquoise waters, and vibrant marine life, this land offers an ideal setting for building your dream home or developing a resort that caters to nature lovers and beach enthusiasts alike. Don't miss out on this exceptional investment opportunity to own a slice of heaven in the South Coast of Lian, Batangas.",
    amenities: [
      "Community Clubhouse",
      "Swimming Pool",
      "Basketball Court",
      "Children's Playground",
      "Fully Landscaped Entrance Gates & Guardhouses",
      "Underground Storm Drainage System",
      "Shade Trees & Mercury Lamps along the Road",
      "Commercial Area"
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 4,
    tags: [],
    link: 'caliraya-spring',
    name: 'Caliraya Spring Golf & Country Club',
    swiper_images: {
      src: [cs_a, cs_b, cs_c, cs_d, cs_e, cs_f, cs_g, cs_h, cs_i],
      alt: "Caliraya Spring Golf & Country Club image"
    },
    location: {
      title: 'Cavinti, Laguna',
      detail: "Barangay East Talaongan, Cavinti, Laguna, Philippines",
    },
    special_offer: "Free Lifetime Golf membership",
    price: '₱ 50,000',
    imageSrc: CalirayaSpringCover,
    imageAlt: 'Caliraya Spring Laguna NBS marketing nolly saludes properties for sale',
    description: "Indulge in the ultimate golfing paradise at Caliraya Spring Golf & Country Club Land realty, located within the picturesque Caliraya Springs Golf and Marina Leisure in Cavinti, Laguna, Philippines. This prestigious development by Sta. Lucia Land Inc. offers an 18-hole championship course that has captured the hearts of golfers with its stunning terrain and meticulous landscaping. Experience the serene beauty of Caliraya Lake as you navigate through the course, enjoying the tranquil ambiance and breathtaking views. With rolling terrains and strategically placed water hazards, this championship course provides both professional golfers and recreational enthusiasts with an exceptional golfing experience. Embrace the harmony of nature and the sport of golf at Caliraya Springs, where every swing is accompanied by captivating scenery and a sense of tranquility.",
    amenities: [
      "Club House",
      "Restaurant",
      "Sauna massage rooms",
      "Hotel accomodation",
      "Practice facilities",
      "Tee Houses",
      "Pro shop",
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 5,
    tags: [],
    link: 'beverly-place',
    name: 'Beverly Place',
    swiper_images: {
      src: [bp_a, bp_b, bp_c, bp_d, bp_e, bp_f, bp_g, bp_h, bp_i, bp_j, bp_k, bp_l, bp_m, bp_n, bp_o],
      alt: "Beverly Place image"
    },
    location: {
      title: 'Mexico, Pampanga',
      detail: "Beverly Place Avenue, Mexico, Pampanga, Philippines",
    },
    price: '₱ 10,000',
    imageSrc: BeverlyPlaceCover,
    imageAlt: 'Beverly Place Pampanga NBS marketing nolly saludes properties for sale',
    description: "Beverly Place at Pampanga offers an exquisite living experience with its meticulously designed homes and lush surroundings, providing a serene and peaceful environment for residents to enjoy. With its prime location and a range of amenities such as golf courses, clubhouses, and recreational facilities, Beverly Place is the epitome of luxurious living in Pampanga. Experience the perfect blend of elegance, comfort, and convenience at Beverly Place, where every day feels like a retreat.",
    amenities: [
      "18-Hole Championship Golf projects",
      "Golf Clubhouse",
      "Grand Lobby",
      "Jacuzzi",
      "Massage Rooms",
      "Golfer's Lounge",
      "Pro shop",
      "Swimming pool and kiddie pool",
      "Covered basketball and tennis court",
      "Driving Range",
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 6,
    tags: [],
    link: 'saddle-club',
    name: 'Saddle Club Liesure Park',
    swiper_images: {
      src: [sc_a, sc_b, sc_c, sc_d, sc_e, sc_f, sc_g, sc_h, sc_i, sc_j, sc_k, sc_l, sc_m, sc_n, sc_o],
      alt: "Saddle Club Liesure Park image"
    },
    location: {
      title: 'Tanza, Cavite',
      detail: "IV A - Southern Tagalog, Naic, 4110 Cavite",
    },
    price: '₱ 10,000',
    imageSrc: SaddleClub,
    imageAlt: "Saddle Club leisure park images",
    description: "In a world of ultimate leisure and excitement. Choose among our exclusive residential estates. Catering to the needs of our privileged residents are unique options for recreation planned to the highest standards of mixed-use community living.",
    amenities: [
      "Community Clubhouse",
      "Swimming Pool",
      "Parking Area",
      "Two covered Tennis Courts",
      "One covered Basketball Court",
      "Two open Basketball Courts",
      "The NEW Sta. Ana Racetrack",
      "Bars and Lounges for Grandstand patrons",
      "Fine Dining Restaurant",
      "Luxury Suites",
      "1,600 and 1,400 meter Turf Track",
      "800 meter Training Track",
      "Track Lightning for night racing",
      "Tunnels to infield for service/pedestrian and horses",
      "Infield with Retention Lakes",
      "Private Stables",
      "Royale Pacific Golf Club",
      "Banquet Hall with Function Rooms",
      "Shopping Mall with Department Store and Supermarket",
      "Two 18-hole Championship Golf Courses designed by Arnold Palmer and Ronald Fream",
    ],
    developer: "Sta. Lucia Land Inc."
  },
  {
    id: 7,
    tags: [],
    link: 'aquamira-hotel',
    name: 'Aquamira Resort & Residences',
    embeded_video: {
      link: "https://www.youtube.com/embed/7UUDhcjYkPo",
      title: "Aquamira Resort, Cavite NOW OPEN!",
    },
    swiper_images: {
      src: [ah_a, ah_b, ah_c, ah_d, ah_e, ah_f, ah_g, ah_h, ah_i, ah_j],
      alt: "Aquamira Resort & Residences image"
    },
    location: {
      title: 'Naic, Cavite',
      detail: "Naic, Cavite",
    },
    price: '₱ 10,000',
    imageSrc: AquamiraHotel,
    imageAlt: "Aquamira Resort & Residences images",
    description: "Aquamira Resort & Residences is a fresh take on luxurious living with an attractive investment. The lifestyle you enjoy here can be shared with others as it is also a FULL-SERVICE CONDOTEL. Each unit opens to a sweeping view of the pool. This is the first time that a condotel is built around a waterpark making it not only pleasurably attractive to you as an investor but also to tourists and vacationers whose desire to enjoy tropical life to the fullest!",
    amenities: [
      "Waterpark",
      "Wave ball",
      "Lazy river",
      "Pool slide",
      "Main pool at each of the clusters of the resort",
      "Clubhouse",
      "Covered basketball court",
      "Covered badminton court",
      "Fitness gym",
      "Coffee shop",
      "Parking spaces",
    ],
    developer: "Sta. Lucia Land Inc."
  },
]