// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import './swiper.style.scss';

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function ImageSlider({ images }) {
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images.src.map((image) => (
          <SwiperSlide key={image}  className=" w-full object-cover object-center">
            <img
              src={image}
              alt={images.alt}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
