import { useParams } from 'react-router-dom';
import ImageSlider from '../swiper/swiper.component';

export default function IsoProperty({ properties }) {
  const {link} = useParams();

  return (
    <div className="bg-white" id='imageHero'>
      <div className="pt-10 sm:pt-24">
        {properties.filter(property => property.link === link).map((property) => (
          <div key={property.name}>
            <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
              {property.swiper_images ? (
                <ImageSlider images={property.swiper_images} />
              ):
              <img style={{maxHeight:'600px'}}
                  src={property.imageSrc}
                  alt={property.imageAlt}
                  className=" w-full object-cover object-center"
                />
              }
            </div>
            <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
              <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                <h1 className="text-2xl font-bold tracking-tight text-primary sm:text-3xl">{property.name}</h1>
              </div>

              <div className="mt-4 lg:row-span-3 lg:mt-0">
                <p className="text-3xl tracking-tight text-secondary">{property.price} reservation fee</p>
                <div className="mt-4">
                  {property.special_offer ? <p className='text-sm text-gray-600 mt-1'><b>Special Offer:</b> {property.special_offer}</p> : ''}
                  <p className='text-sm text-gray-600 mt-1'><b>Project:</b> {property.name}</p>
                  <p className='text-sm text-gray-600 mt-1'><b>Developer:</b> {property.developer}</p>
                  <p className='text-sm text-gray-600 mt-1'><b>Location:</b> {property.location.detail}</p>
                </div>

                <form className="mt-10">
                  <a
                    href="https://www.facebook.com/Nolskie1975"
                    target='blank'
                    className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-primary-green px-8 py-3 text-base font-medium text-white hover:bg-secondary-green focus:outline-none focus:ring-2"
                  >
                    Book free tripping
                  </a>
                </form>
              </div>

              <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                <div>
                  <h3 className="sr-only">Description</h3>
                  <div className="space-y-6">
                    <p className="text-sm leading-relaxed text-gray-900">{property.description}</p>
                  </div>
                </div>

                {property.embeded_video ? <iframe className='pt-10' src={property.embeded_video.link} title={property.embeded_video.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen /> : ''}

                <div className="mt-10">
                  <h3 className="text-sm font-medium text-gray-900">Feature and Amenities</h3>
                  <div className="mt-4">
                    <ul className="list-disc space-y-2 pl-4 text-sm">
                      {property.amenities.map((amenity) => (
                        <li key={amenity} className="text-gray-400">
                          <span className="text-gray-600">{amenity}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                
                {property.additional_details ?
                <div className="mt-10">
                  <h2 className="text-sm font-medium text-gray-900">Details</h2>
                  <div className="mt-4 space-y-6">
                    <p className="text-sm text-gray-600">{property.additional_details}</p>
                  </div>
                </div>
                : ""}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
