
import './hero.style.scss';

export default function Hero() {
  return (
    <div className="bg-white hero" id='hero'>
      <div className="px-6 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-28">
          <div className=" flex justify-center xs:mb-8">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Want a free tripping?{' '}
              <a href="https://www.facebook.com/Nolskie1975" target='blank' className="font-semibold text-secondary">
                <span className="absolute inset-0" aria-hidden="true" />
                Follow us <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-primary sm:text-6xl">
              Discover the endless possibilities of land ownership
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-700">
              We believe that owning a piece of land is a fundamental right and a key to financial freedom. Let us help you achieve your real estate goals and invest in your future.
            </p>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
        </div>
      </div>
    </div>
  )
}
