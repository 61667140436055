import Navbar from '../components/navbar/navbar.component';
import IsoProperty from '../components/iso-property/iso-property.component';
import Footer from '../components/footer/footer.component';
import MessengerChat from '../components/MessengerChat/MessengerChat.component';


const navigation = [
  {tag: 'link', name: 'Home', href: '/', current: false },
  {tag: 'LinkScroll', name: 'Properties', href: 'imageHero', current: true, offset: 0 },
]

export default function Main({properties}) {
  return (
    <>
      <Navbar navigation={navigation}/>
      <IsoProperty properties={properties} />
      <Footer />
      {/* <MessengerChat /> */}
    </>
  )
}