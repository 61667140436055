import Ceo from '../../assets/site-images/ceo.jpeg';

const posts = [
  {
    id: 1,
    title: 'Caliraya Spring Golf & Country Club',
    href: 'https://fb.watch/kJaNAHOfkT/',
    description:
      'Fairway Lot available 600sqm Minimum Lot area with Free Lifetime Golf membership. Only here on Caliraya Spring Golf & Country Club.',
    date: 'May 6, 2023',
    datetime: '2023-05-06',
    category: { title: 'House & Lot', href: '#' },
    author: {
      name: 'Nolly Saludes',
      role: 'International Sales Director',
      href: 'https://www.facebook.com/Nolskie1975',
      imageUrl: Ceo,
    },
  },
  {
    id: 2,
    title: 'Caliraya Spring Golf & Country Club',
    href: 'https://www.facebook.com/Nolskie1975/posts/pfbid02PNnQrhchWqc2tpRA1qMXx9GfUiiwFFGXjEDytrSkAuPSB6nMc3WvggyBzvRDHzADl',
    description:
      'Caliraya Spring Golf & Country Club located at Cavinti Laguna. Residential lot for over 5 years to pay with no interest and with Free lifetime Golf Membership.',
    date: 'May 07, 2023',
    datetime: '2020-05-07',
    category: { title: 'Residential Fairway Lot', href: '#' },
    author: {
      name: 'Nolly Saludes',
      role: 'International Sales Director',
      href: 'https://www.facebook.com/Nolskie1975',
      imageUrl: Ceo,
    },
  },
  {
    id: 3,
    title: 'Eagle Ridge Golf & Country Club',
    href: 'https://www.facebook.com/Nolskie1975/posts/pfbid027Kf94kkEEYvyWvVDYPqsPhqj57oHNvmCi2fpv3zM63QSz2sp2ijSXWXp7gUtmJMNl',
    description:
      'Residential lot only for sale in Eagle Ridge Golf & Country Club. Brgy. Javalera General Trias cavite. Free Lifetime Golf Membership.',
    date: 'May 12, 2023',
    datetime: '2023-05-12',
    category: { title: 'Residential Fairway Lot', href: '#' },
    author: {
      name: 'Nolly Saludes',
      role: 'International Sales Director',
      href: 'https://www.facebook.com/Nolskie1975',
      imageUrl: Ceo,
    },
  },
]

export default function Blogs() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-primary">Our Latest Blogs</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600" id="blogs">
            You can virtually visits our site by watching our blogs.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <p
                  className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600"
                >
                  {post.category.title}
                </p>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href} target='blank'>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <a href={post.author.href} target='blank'>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}