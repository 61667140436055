'use client';
import { FacebookProvider, CustomChat } from 'react-facebook';

const MessengerChat = () => {
  return (
    <FacebookProvider appId="206537368881206" chatSupport>
      <CustomChat pageId="778888185526970" minimized={true}/>
    </FacebookProvider>    
  );
}

export default MessengerChat;