import Navbar from '../components/navbar/navbar.component';
import Hero from '../components/hero/hero.component';
import Body from '../components/body/body.component';
import About from '../components/about/about.component';
import Blog from '../components/blog/blog.component';
import Footer from '../components/footer/footer.component';
import MessengerChat from '../components/MessengerChat/MessengerChat.component';

const navigation = [
  {tag: 'linkScroll', name: 'Home', href: 'hero', current: true, offset: 0 },
  {tag: 'linkScroll', name: 'Properties', href: 'properties', current: false, offset: -220 },
  {tag: 'linkScroll', name: 'About Us', href: 'about', current: false, offset: -220 },
  {tag: 'linkScroll', name: 'Blogs', href: 'blogs', current: false, offset: -180 },
]

export default function Main({properties}) {
  return (
    <>
      <Navbar navigation={navigation}/>
      <Hero />
      <Body properties={properties}/>
      <About />
      <Blog />
      <Footer />
      {/* <MessengerChat /> */}
    </>
  )
}