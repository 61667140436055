import AboutCover from '../../assets/site-images/about-cover.jpg';

const links = [
  { name: 'Free Tripping', href: '#' },
  { name: 'Inquiries', href: '#' },
]
const stats = [
  { name: 'Land Properties', value: '300+' },
  { name: 'Housing Properties', value: '250+' },
  { name: 'Office Hours', value: '7:00 AM - 7:00 PM' },
  { name: 'Free Tripping', value: 'Mondays - Saturdays' },
]

export default function About() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <img src={AboutCover} alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white">About Us</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300" id='about'>
           Whether you are looking for a land property or a new home for your family we got you! NBS Marketing offers a free tripping promo so you can picture your future life or business to your new property. We always give special care to our clients being transparent and giving assurance on what we do, providing what our clients needs for them to be hassle and stress free.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}